import React from "react"
import Layout from "../components/Layout"
import About from "../components/About"

const seoProps = {
  title: "About",
  description:
    "Stevenson Roofing is a family run roofing business involved in the building industry for over 30 years, based in Wishaw, North Lanarkshire.",
  image: "/img/tools1.jpg",
  pathname: "/about",
}

const AboutPage = () => (
  <Layout seoProps={seoProps}>
    <About />
  </Layout>
)

export default AboutPage
